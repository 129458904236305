"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
export default class DreamDesignComponent extends DcBaseComponent {
  constructor(...args) {
    super(...args);
  }
  static getNamespace() {
    return "dream-design";
  }
  static getRequiredRefs() {
    return ["toggle", "dream", "design"];
  }
  onInit() {
    this.isTouchEabled = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    this.element.classList.add("is-touch");
    this._addListeners();
  }
  _addListeners() {
    this.addListener(this.refs.toggle, "click", this._onToggleClick.bind(this));
    this.addListener(this.refs.dream, "mouseenter", this._onDreamHover.bind(this));
    this.addListener(this.refs.design, "mouseenter", this._onDesignHover.bind(this));
    this.addListener(this.refs.dream, "mouseleave", this._onDreamUnhover.bind(this));
    this.addListener(this.refs.design, "mouseleave", this._onDesignUnhover.bind(this));
  }
  _onDreamHover() {
    this.element.classList.remove("is-design-active");
    this.element.classList.add("is-dream-active");
  }
  _onDreamUnhover() {
    if (this.isTouchEabled)
      return;
    this.element.classList.remove("is-dream-active");
  }
  _onDesignHover() {
    this.element.classList.remove("is-dream-active");
    this.element.classList.add("is-design-active");
  }
  _onDesignUnhover() {
    if (this.isTouchEabled)
      return;
    this.element.classList.remove("is-design-active");
  }
  _onToggleClick() {
    if (this.element.classList.contains("is-design-active")) {
      this._onDreamHover();
    } else {
      this._onDesignHover();
    }
  }
}
