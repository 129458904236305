"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { gsap } from "gsap";
import Player from "@vimeo/player";
export default class SplashComponent extends DcBaseComponent {
  static getNamespace() {
    return "splash";
  }
  static getRequiredRefs() {
    return [
      "logo",
      "video",
      "logoTop",
      "skip"
    ];
  }
  onInit() {
    if (window.location.hash === "#split") {
      this.element.style.display = "none";
      return;
    }
    this._addListeners();
    this._initVideo();
    const timeline = gsap.timeline();
    timeline.addLabel("screen1", 4);
    timeline.to(this.refs.logo, { opacity: 0, duration: 1 }, 1.5).to(this.refs.logoTop, { opacity: 1, duration: 0.5 }, "screen1").to(this.refs.video, { opacity: 1, duration: 0.5 }, 1.5);
    this.player.on("ended", () => {
      timeline.to(this.element, { opacity: 0, display: "none" });
    });
  }
  _addListeners() {
    this.addListener(this.refs.skip, "click", this._onSkipClick.bind(this));
  }
  _initVideo() {
    var _a, _b;
    const iframe = this.refs.video.querySelector("iframe");
    iframe.src = window.innerHeight < window.innerWidth ? `https://player.vimeo.com/video/${(_a = this.options) == null ? void 0 : _a.srcDesktop}?title=0&byline=0&muted=1&autoplay=1&controls=0&loop=0&autopause=0&quality=1080p&background=1` : `https://player.vimeo.com/video/${(_b = this.options) == null ? void 0 : _b.srcMobile}?title=0&byline=0&muted=1&autoplay=1&controls=0&loop=0&autopause=0&quality=540p&background=1`;
    this.player = new Player(iframe);
  }
  _onSkipClick(e) {
    e.preventDefault();
    this.element.style.display = "none";
    this.player.pause();
  }
}
